import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import I18n from '../I18n/I18n';
import Routes from '../Routes/Routes';
import AppHeader from '../Shared/AppHeader/AppHeader';
import Layout from '../Shared/Layout/Layout';

function App() {
  return (
    <BrowserRouter>
      <I18n>
        <Layout>
          <AppHeader />
          <Routes />
        </Layout>
      </I18n>
    </BrowserRouter>
  );
}

export default App;
