import create from 'zustand';
import axios from '../api/api';

const useStore = create((set) => ({
  balance: 0,
  setBalance: (balance) => set(() => ({ balance })),
  getBalance: async () => {
    const balanceResult = await axios.post(
      `${process.env.REACT_APP_API_LUCKY}/balance`,
    );
    set({ balance: balanceResult.data.balance });
  },
}));

export default useStore;
