import axios from 'axios';

axios.interceptors.request.use((config) => {
  const userId = sessionStorage.getItem(process.env.REACT_APP_USER_ID);
  config.headers['authorization'] = sessionStorage.getItem(
    process.env.REACT_APP_USER_TOKEN,
  );
  config.headers['userid'] = userId;
  return config;
});

export default axios;
