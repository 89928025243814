import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import en from '../../config/I18n/en';
import es from '../../config/I18n/es';
import pt from '../../config/I18n/pt';

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  pt: {
    translation: pt,
  },
};

const I18n = memo(({ children }) => {
  // eslint-disable-next-line no-undef
  const locale = navigator && navigator.language;
  const language = locale.substring(0, 2);

  i18n.use(initReactI18next).init({
    detection: { order: ['path', 'navigator'] },
    resources,
    lng: language,
    keySeparator: true,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return <>{children}</>;
});

I18n.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

I18n.defaultProps = {
  children: null,
};

I18n.displayName = 'I18n';

export default I18n;
