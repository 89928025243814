const defaults = {
  'default.ok': 'Ok',
  'default.close': 'Close',
};

const session = {
  'session.expired': 'Session expired',
};

const header = {
  'header.balance': 'Balance',
};

const luckyNumbers = {
  'luckyNumbers.choose': 'Choose Your Lucky Number',
  'luckyNumbers.quickySelect': 'Quick select',
  'luckyNumbers.amount.bet': 'Dau/Amount TSH',
  'luckyNumbers.amount.play': 'Play',
};

const spin = {
  'spin.ticket': 'TICKET',
  'spin.yourPick': 'Your pick',
  'spin.winningNumber': 'Winning number',
  'spin.yourWinnings': 'Your winnings',
};

const winLose = {
  'winLose.lose': 'try again',
  'winLose.won': 'you won',
  'winLose.won.firstNumber': 'you won, first number',
  'winLose.won.firstTwoNumbers': 'you won, first 2 numbers',
  'winLose.won.firstThreeNumbers': 'you won, first 3 numbers',
  'winLose.won.firstFourNumbers': 'you won, first 4 numbers',
  'winLose.won.firstAndLastNumbers': 'you won, first and last numbers',
  'winLose.won.lastNumber': 'you won, last number',
  'winLose.won.middleNumber': 'you won, middle number',
};

const modalDefault = {
  'modalDefault.prizes': 'PRIZES',
  'modalDefault.drawPick': 'DRAW/PICK',
  'modalDefault.bet': 'BET',
  'modalDefault.numbersPick': 'PICK',
  'modalDefault.numbersWin': 'WIN',
  'modalDefault.multiplier': 'MULT.',
  'modalDefault.yourWinnings': 'WINNINGS',
};

const en = {
  ...defaults,
  ...luckyNumbers,
  ...header,
  ...spin,
  ...winLose,
  ...session,
  ...modalDefault,
};

export default en;
