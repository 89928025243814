import React, { useEffect, useState } from 'react';
import useStore from '../../../stores/balanceStore';
import { useTranslation } from 'react-i18next';
import ModalPrizes from '../ModalPrizes/ModalPrizes';
import ProjectSettings from '../../../../package.json';
import { useLocation } from 'react-router';

function AppHeader() {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const balance = useStore((state) => state.balance);
  const getBalance = useStore((state) => state.getBalance);
  const language = sessionStorage.getItem(process.env.REACT_APP_USER_LANGUAGE);
  const currency = sessionStorage.getItem(process.env.REACT_APP_USER_CURRENCY);

  const balanceFormatted = new Intl.NumberFormat(language || 'en-US', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 0,
  }).format(balance || 0);

  const showBalance = process.env.REACT_APP_SHOW_BALANCE;

  useEffect(() => {
    if (showBalance === 'true') {
      setTimeout(() => {
        getBalance();
      }, 3000);

      const getBalanceInterval = setInterval(() => {
        getBalance();
      }, 30000);

      return () => {
        clearInterval(getBalanceInterval);
      };
    }
  }, [showBalance, getBalance]);

  if (pathname.includes('play-from')) return <></>;

  const handleOpenModal = async () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      style={{
        height: '56px',
        backgroundColor: 'var(--header)',
        marginBottom: '50px',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
        lineHeight: '1.5',
        fontSize: '0.9rem',
        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 100%)',
      }}
    >
      <div style={{ flex: 1, marginLeft: '10px', alignItems: 'center' }}>
        <span style={{ cursor: 'pointer' }} onClick={handleOpenModal}>
          Prizes
        </span>
      </div>

      {showBalance === 'true' && (
        <span style={{ textTransform: 'uppercase', marginRight: '10px' }}>
          {t('header.balance')}: {balanceFormatted}
        </span>
      )}

      <span style={{ fontSize: '10px', marginRight: '10px' }}>
        v{ProjectSettings.version}
      </span>

      <ModalPrizes
        modalIsOpen={modalIsOpen}
        closeModal={handleCloseModal}
      ></ModalPrizes>
    </div>
  );
}

export default AppHeader;
