const defaults = {
  'default.ok': 'Ok',
  'default.close': 'Encerrar',
};

const session = {
  'session.expired': 'Sesión expirada',
};

const header = {
  'header.balance': 'Saldo',
};

const luckyNumbers = {
  'luckyNumbers.choose': 'Elija su número de la suerte',
  'luckyNumbers.quickySelect': 'Selección rápida',
  'luckyNumbers.amount.bet': 'Monto TSH',
  'luckyNumbers.amount.play': 'Jugar',
};

const spin = {
  'spin.ticket': 'TICKET',
  'spin.yourPick': 'Tu Selección',
  'spin.winningNumber': 'Numero ganador',
  'spin.yourWinnings': 'Ganaste',
};

const winLose = {
  'winLose.lose': 'intentar otra vez',
  'winLose.won': 'ganaste',
  'winLose.won.firstNumber': 'ganaste, primer número',
  'winLose.won.firstTwoNumbers': 'ganaste, primeros 2 números',
  'winLose.won.firstThreeNumbers': 'ganaste, primeros 3 números',
  'winLose.won.firstFourNumbers': 'ganaste, primeros 4 números',
  'winLose.won.firstAndLastNumbers': 'ganaste, primer y ultimo numero',
  'winLose.won.lastNumber': 'ganaste, ultimo numero',
  'winLose.won.middleNumber': 'ganaste, número medio',
};

const modalDefault = {
  'modalDefault.prizes': 'PRIZES',
  'modalDefault.drawPick': 'DRAW/PICK',
  'modalDefault.bet': 'BET',
  'modalDefault.numbersPick': 'PICK',
  'modalDefault.numbersWin': 'WIN',
  'modalDefault.multiplier': 'MULT.',
  'modalDefault.yourWinnings': 'WINNINGS',
};

const es = {
  ...defaults,
  ...luckyNumbers,
  ...header,
  ...spin,
  ...winLose,
  ...session,
  ...modalDefault,
};

export default es;
