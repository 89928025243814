import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Spin from '../../Shared/Spin/Spin';
import Loading from '../Loading/Loading';

import './ModalSpin.css';
import FormatValue from '../FormatValue/FormatValue';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

function ModalSpin(props) {
  const { t } = useTranslation();

  return (
    <Modal
      className={props.error ? 'modal modal-error' : 'modal modal-ok'}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {props.error ? (
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'var(--text)',
            }}
          >
            {props.error}
          </p>

          <button
            style={{ marginTop: '60px' }}
            className="button-default"
            onClick={props.closeModal}
          >
            {t('default.ok')}
          </button>
        </div>
      ) : props.loading ? (
        <>
          <div style={{ marginBottom: '40px' }} className="modal-header">
            <p className="card-header-text">
              {t('spin.ticket')} #{props.ticket.toString()}
            </p>
          </div>
          <Loading />
        </>
      ) : (
        <>
          <div className="modal-header">
            <p className="card-header-text">
              {t('spin.ticket')} #{props.ticket.toString()}
            </p>
          </div>
          <div className="modal-container">
            <p style={{ color: 'var(--text)' }}>
              {t('spin.yourPick')}:<span>{props.selectedNumbers}</span>
            </p>
            <p style={{ color: 'var(--text)' }}>
              {t('spin.winningNumber')}:
              <span className="fade-in-text">{props.winNumberShow}</span>
            </p>
            <p style={{ color: 'var(--text)' }}>
              {t('spin.yourWinnings')}:
              <FormatValue classTo="fade-in-text" value={props.winValue} />
            </p>
            <Spin
              from={props.selectedNumbersToSpin}
              to={props.winNumber}
              opened={props.modalIsOpen}
            />
          </div>
          <p
            className="fade-in-text"
            style={{
              fontSize: '14px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'var(--text)',
            }}
          >
            {t(props.winMsg)} {props.error}
          </p>
          <div className="modal-footer">
            <button className="button-default close" onClick={props.closeModal}>
              {t('default.ok')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}

ModalSpin.propTypes = {
  winNumberShow: PropTypes.string,
  winMsg: PropTypes.string,
  winValue: PropTypes.string,
  winNumber: PropTypes.any,
  error: PropTypes.any,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  selectedNumbers: PropTypes.string,
  selectedNumbersToSpin: PropTypes.string,
  ticket: PropTypes.number,
  loading: PropTypes.bool,
};

export default ModalSpin;
