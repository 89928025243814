import React from 'react';
import PropTypes from 'prop-types';

function FormatValue(props) {
  const language = sessionStorage.getItem(process.env.REACT_APP_USER_LANGUAGE);
  const currency = sessionStorage.getItem(process.env.REACT_APP_USER_CURRENCY);
  const value = parseInt(props.value);

  const valueFormatted = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  }).format(value);

  return <span className={props.classTo}>{valueFormatted}</span>;
}

FormatValue.propTypes = {
  value: PropTypes.string,
  classTo: PropTypes.string,
};

export default FormatValue;
