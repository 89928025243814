import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/Shared/Loading/Loading';
import Axios from './../../api/api';

function StartPlaying() {
  const { sessionId } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    async function startSession() {
      try {
        const { data } = await Axios.post(
          `${process.env.REACT_APP_API_LUCKY}/start-session`,
          {
            sessionId,
          },
        );

        sessionStorage.setItem(process.env.REACT_APP_USER_ID, data.userId);
        sessionStorage.setItem(process.env.REACT_APP_USER_TOKEN, data.token);
        sessionStorage.setItem(
          process.env.REACT_APP_USER_LANGUAGE,
          data.language,
        );
        sessionStorage.setItem(
          process.env.REACT_APP_USER_CURRENCY,
          data.currency,
        );
        sessionStorage.setItem('home-link', data.homeUrl);
        setTimeout(() => {
          push('/lucky-numbers');
        }, 3000);
      } catch (err) {
        push('/');
      }
    }
    startSession();
  }, [sessionId, push]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIndicator global />
    </div>
  );
}

export default StartPlaying;
