import { Route, Switch } from 'react-router-dom';
import LuckyNumbers from '../../pages/LuckyNumbers/LuckyNumbers';
import SessionExpired from '../../pages/SessionExpired/SessionExpired';
import StartPlaying from '../../pages/StartPlaying/StartPlaying';
import Loading from '../Shared/Loading/Loading';

const routes = [
  { path: '/', Component: SessionExpired, exact: true },
  { path: '/loading', Component: Loading, exact: true },
  { path: '/lucky-numbers', Component: LuckyNumbers, exact: true },
  {
    path: `/play-from/${process.env.REACT_APP_PLAY_FROM}/:sessionId`,
    Component: StartPlaying,
    exact: true,
  },
];

const Routes = () => {
  return (
    <>
      <Switch>
        {routes.map(({ path, Component, exact }, idx) => (
          <Route path={path} component={Component} key={idx} exact={exact} />
        ))}
      </Switch>
    </>
  );
};

export default Routes;
