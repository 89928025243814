import styled from 'styled-components';

import loader from '../../../assets/img/sysgamingLoader.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
`;

export const LoadingSysgaming = styled.div`
  background: url(${loader}) center no-repeat;
  background-size: cover;

  min-width: 250px;
  min-height: 250px;

  @media only screen and (max-width: 768px) {
    min-width: 200px;
    min-height: 200px;
  }
  @media only screen and (max-width: 576px) {
    min-width: 150px;
    min-height: 150px;
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
`;
