import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bounty from 'bounty';

import './Spin.css';

function Spin(props) {
  useEffect(() => {
    bounty({
      el: '.slot',
      value: props.to,
      initialValue: props.from,
      lineHeight: 1.5,
      letterSpacing: 1.5,
      animationDelay: 100,
      letterAnimationDelay: 100,
      duration: 3500,
    });
  }, [props.to, props.from]);

  return <div className="slot"></div>;
}

Spin.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  opened: PropTypes.bool,
};

export default Spin;
