import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './ReelContainer.css';

import ArrowUp from '../../../assets/img/chevron-up.svg';
import ArrowDown from '../../../assets/img/chevron-down.svg';

import useSound from 'use-sound';
import clickSound from '../../../assets/sounds/click.mp3';
import useThrottle from '../../../utils/use-throttle';

function ReelContainer(props) {
  const [click] = useSound(clickSound);
  const [touchStartLocation, setTouchStartLocation] = useState(0);
  const throttledCb = useThrottle((ev, index) => changeHandle(ev, index), 200);

  const handleOnWheel = (ev, reelIndex) => {
    click();

    const currentReel = props.reelsContainer[reelIndex];
    let nextReelNumber = 0;

    if (ev.deltaY < 0) {
      nextReelNumber = currentReel == 0 ? 9 : currentReel - 1;
    } else {
      nextReelNumber = currentReel == 9 ? 0 : currentReel + 1;
    }

    const updatedReelsContainer = [...props.reelsContainer];
    updatedReelsContainer[reelIndex] = nextReelNumber;
    props.setReelsContainer(updatedReelsContainer);
  };

  const handleOnTouchStart = (ev) => {
    setTouchStartLocation(ev.changedTouches[0].clientY);
  };

  const changeHandle = (ev, index) => {
    const currentReel = props.reelsContainer[index];
    let nextReelNumber = 0;

    if (ev.changedTouches[0].clientY < touchStartLocation) {
      nextReelNumber = currentReel == 0 ? 9 : currentReel - 1;
    }

    if (ev.changedTouches[0].clientY > touchStartLocation) {
      nextReelNumber = currentReel == 9 ? 0 : currentReel + 1;
    }

    const updatedReelsContainer = [...props.reelsContainer];
    updatedReelsContainer[index] = nextReelNumber;
    props.setReelsContainer(updatedReelsContainer);
  };

  const handleOnTouchMove = (ev, index) => {
    throttledCb(ev, index);
  };

  const getLastReelNumber = (reel) => {
    if (reel == 0) return 9;
    return reel - 1;
  };

  const getNextReelNumber = (reel) => {
    if (reel == 9) return 0;
    return reel + 1;
  };

  const handleArrowClickUp = (reel, reelIndex) => {
    click();
    setReelUpDown(reel, reelIndex, true);
  };

  const handleArrowClickDown = (reel, reelIndex) => {
    click();
    setReelUpDown(reel, reelIndex, false);
  };

  const setReelUpDown = (reel, reelIndex, up) => {
    let nextReelNumber = 0;
    up
      ? (nextReelNumber = reel == 0 ? 9 : reel - 1)
      : (nextReelNumber = reel == 9 ? 0 : reel + 1);
    const updatedReelsContainer = [...props.reelsContainer];
    updatedReelsContainer[reelIndex] = nextReelNumber;
    props.setReelsContainer(updatedReelsContainer);
  };

  return (
    <div className="group">
      {props.reelsContainer.map((reel, index) => {
        return (
          <div
            className={
              index === props.reelsContainer.length - 1 ? 'reel-last' : 'reel'
            }
            onWheel={(ev) => handleOnWheel(ev, index)}
            onTouchStart={(ev) => handleOnTouchStart(ev)}
            onTouchMove={(ev) => handleOnTouchMove(ev, index)}
            key={index}
          >
            <div key={`${index}-1`}>
              <p className="reel-number disable-select">
                {getLastReelNumber(reel)}
              </p>
            </div>
            <div key={`${index}-2`}>
              <p className="reel-number disable-select">{reel}</p>
            </div>
            <div key={`${index}-3`}>
              <p className="reel-number disable-select">
                {getNextReelNumber(reel)}
              </p>
            </div>

            <div
              className="area-click-up disable-select"
              onClick={() => handleArrowClickUp(reel, index)}
            ></div>

            <div
              className="area-click-down disable-select"
              onClick={() => handleArrowClickDown(reel, index)}
            ></div>

            <div className="div-arrow-up disable-select">
              <img className="arrow-up" src={ArrowUp}></img>
            </div>
            <div className="div-arrow-down disable-select">
              <img className="arrow-down" src={ArrowDown}></img>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ReelContainer.propTypes = {
  reelsContainer: PropTypes.array,
  setReelsContainer: PropTypes.func,
};

export default ReelContainer;
