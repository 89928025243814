import React from 'react';
import { useTranslation } from 'react-i18next';

function SessionExpired() {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center', color: '#fff' }}>
      {t('session.expired')}!
    </div>
  );
}

export default SessionExpired;
