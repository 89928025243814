import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './ModalPrizes.css';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

function ModalPrizes(props) {
  const { t } = useTranslation();

  const modal = {
    cell: {
      drawPick: t('modalDefault.drawPick'),
      bet: t('modalDefault.bet'),
      numbersPick: t('modalDefault.numbersPick'),
      numbersWin: t('modalDefault.numbersWin'),
      multiplier: t('modalDefault.multiplier'),
      yourWinnings: t('modalDefault.yourWinnings'),
    },
  };

  return (
    <Modal
      className="modal-prizes"
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      {/* {modal} */}
      <div style={{ marginBottom: '40px' }} className="modal-header">
        <p className="card-header-text">{t('modalDefault.prizes')}</p>
      </div>
      <div className="modal-container">
        <div className="wrapper">
          <div className="table">
            <div className="row header">
              <div className="cell">{modal.cell.drawPick} 1</div>
              <div className="cell">{modal.cell.bet}</div>
              <div className="cell">{modal.cell.numbersPick}</div>
              <div className="cell">{modal.cell.numbersWin}</div>
              <div className="cell">{modal.cell.multiplier}</div>
              <div className="cell">{modal.cell.yourWinnings}</div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x5
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 10,000
              </div>
            </div>
          </div>

          <div className="table">
            <div className="row header green">
              <div className="cell">{t('modalDefault.drawPick')} 2</div>
              <div className="cell">{t('modalDefault.bet')}</div>
              <div className="cell">{t('modalDefault.numbersPick')}</div>
              <div className="cell">{t('modalDefault.numbersWin')}</div>
              <div className="cell">{t('modalDefault.multiplier')}</div>
              <div className="cell">{t('modalDefault.yourWinnings')}</div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 2
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>12</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>12</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x25
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 50,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
                2
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
                9
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
          </div>

          <div className="table">
            <div className="row header blue">
              <div className="cell">{t('modalDefault.drawPick')} 3</div>
              <div className="cell">{t('modalDefault.bet')}</div>
              <div className="cell">{t('modalDefault.numbersPick')}</div>
              <div className="cell">{t('modalDefault.numbersWin')}</div>
              <div className="cell">{t('modalDefault.multiplier')}</div>
              <div className="cell">{t('modalDefault.yourWinnings')}</div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 3
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>123</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>123</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x250
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 500,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 2
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>12</u>
                </b>
                3
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>12</u>
                </b>
                9
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x5
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 10,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
                23
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
                89
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
          </div>
          <div className="table">
            <div className="row header cian">
              <div className="cell">{t('modalDefault.drawPick')} 4</div>
              <div className="cell">{t('modalDefault.bet')}</div>
              <div className="cell">{t('modalDefault.numbersPick')}</div>
              <div className="cell">{t('modalDefault.numbersWin')}</div>
              <div className="cell">{t('modalDefault.multiplier')}</div>
              <div className="cell">{t('modalDefault.yourWinnings')}</div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 4
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1234</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1234</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1,500
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 3,000,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 3
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>123</u>
                </b>
                4
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>123</u>
                </b>
                9
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x50
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 100,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 2
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>12</u>
                </b>
                34
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>12</u>
                </b>
                89
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x5
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 10,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
                234
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
                789
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
          </div>
          <div className="table">
            <div className="row header pink">
              <div className="cell">{t('modalDefault.drawPick')} 5</div>
              <div className="cell">{t('modalDefault.bet')}</div>
              <div className="cell">{t('modalDefault.numbersPick')}</div>
              <div className="cell">{t('modalDefault.numbersWin')}</div>
              <div className="cell">{t('modalDefault.multiplier')}</div>
              <div className="cell">{t('modalDefault.yourWinnings')}</div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 5
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>12345</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>12345</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x15,000
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 30,000,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 4
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1234</u>5
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1234</u>9
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x200
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 400,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 3
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>123</u>45
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>123</u>89
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x10
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 20,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 2
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>12</u>345
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>12</u>789
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x5
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 10,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare the first 1 number with the last 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
                234
                <b>
                  <u>5</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
                678
                <b>
                  <u>5</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x2
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 4,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare the first number 1
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                <b>
                  <u>1</u>
                </b>
                2345
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                <b>
                  <u>1</u>
                </b>
                6789
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare the last 1 number
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                1234
                <b>
                  <u>5</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                6789
                <b>
                  <u>5</u>
                </b>
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
            <div className="row">
              <div className="cell" data-title={t('modalDefault.drawPick')}>
                Compare number 1 in the middle
              </div>
              <div className="cell" data-title={t('modalDefault.bet')}>
                Tzs 2,000
              </div>
              <div className="cell" data-title={t('modalDefault.numbersPick')}>
                12
                <b>
                  <u>3</u>
                </b>
                45
              </div>
              <div className="cell" data-title={t('modalDefault.numbersWin')}>
                67
                <b>
                  <u>3</u>
                </b>
                89
              </div>
              <div className="cell" data-title={t('modalDefault.multiplier')}>
                x1
              </div>
              <div className="cell" data-title={t('modalDefault.yourWinnings')}>
                Tzs 2,000
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="button-default close" onClick={props.closeModal}>
          {t('default.ok')}
        </button>
      </div>
    </Modal>
  );
}

ModalPrizes.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ModalPrizes;
