import React, { useRef, useState } from 'react';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import useStore from '../../stores/balanceStore';

import Axios from './../../api/api';
import ModalSpin from '../../components/Shared/ModalSpin/ModalSpin';
import ReelContainer from '../../components/Shared/ReelContainer/ReelContainer';

import clickSound from '../../assets/sounds/click.mp3';
import coinSound from '../../assets/sounds/coins.mp3';
import coinInsertSound from '../../assets/sounds/coin-insert.mp3';
import winSound from '../../assets/sounds/win.mp3';

import './LuckyNumbers.css';

function LuckyNumbers() {
  const { t } = useTranslation();

  const [reelsContainer, setReelsContainer] = useState([0, 1, 4, 7, 9]);
  const [selectedButton, setSelectedButton] = useState(5);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [betValue, setBetValue] = useState(500);
  const [winNumber, setWinNumber] = useState(null);
  const [winValue, setWinValue] = useState('...');
  const [winMsg, setWinMsg] = useState('');
  const [winNumberShow, setWinNumberShow] = useState('...');
  const [ticket, setTicket] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const buttonBetRef = useRef();

  const setBalance = useStore((state) => state.setBalance);

  const [click] = useSound(clickSound);
  const [coin] = useSound(coinSound);
  const [coinInsert] = useSound(coinInsertSound);
  const [win] = useSound(winSound);

  const buttonNumbers = Array.from({ length: 5 }, (_, idx) => idx + 1);
  const selectedNumbers = reelsContainer.join('');
  const selectedNumbersToSpin = Array(reelsContainer.length).join('0') + '0';

  const handleSelectNumber = (value) => {
    click();
    setSelectedButton(value);
    let columsToSelect = [...Array(parseInt(value)).keys()];
    for (let i = 0; i < columsToSelect.length; i++) {
      columsToSelect[i] = Math.floor(Math.random() * 9) + 1;
    }
    setReelsContainer(columsToSelect);
  };

  const handleChangeValue = (event) => {
    const betValue = parseInt(event.target.value);
    setBetValue(betValue < 2000 ? betValue : 2000);
  };

  const handleClickPlusValue = () => {
    coin();
    setBetValue((previousState) => {
      const updatedState = previousState + 500;
      return updatedState < 2000 ? updatedState : 2000;
    });
  };

  const handleClickMinusValue = () => {
    coin();
    setBetValue((previousState) => {
      const updatedState = previousState - 500;
      return updatedState > 0 ? updatedState : 500;
    });
  };

  const handleOpenModal = async () => {
    if (betValue < 500) {
      setModalIsOpen(true);
      setError('bet value under 500');
      return;
    }

    if (buttonBetRef.current) {
      buttonBetRef.current.setAttribute('disabled', 'disabled');
    }

    coinInsert();

    try {
      const payLoad = {
        qtdNumbers: reelsContainer.length,
        betValue: betValue,
        selectedNumbers: selectedNumbers,
      };

      setModalIsOpen(true);
      setLoading(true);
      const result = await Axios.post(
        `${process.env.REACT_APP_API_LUCKY}/start-bet`,
        payLoad,
      );

      setLoading(false);
      setWinNumber(String(result.data.winNumber));
      setWinValue(String(result.data.win));
      setTicket(result.data.ticket);
      setBalance(result.data.balance);

      if (result.data.win > 0) {
        setTimeout(() => {
          win();
        }, 3000);
      }
      setWinMsg(result.data.winMsg);
      setWinNumberShow(String(result.data.winNumber));
    } catch (err) {
      if (err.response) {
        setModalIsOpen(true);
        setError(err.response.data.message);
      }
    }
  };

  const handleCloseModal = () => {
    click();
    setTicket(0);
    setError(null);
    setWinNumberShow('...');
    setWinValue('...');
    setWinNumber('...');
    setWinMsg('...');
    setModalIsOpen(false);
    if (buttonBetRef.current) {
      buttonBetRef.current.removeAttribute('disabled');
    }
  };

  return (
    <div className="container">
      <div className={modalIsOpen ? 'card disable' : 'card'}>
        <div className="card-header">
          <p className="card-header-text">{t('luckyNumbers.choose')}</p>
        </div>
        <div className="container-card">
          <div className="container-button-header">
            <p className="card-text">{t('luckyNumbers.quickySelect')}:</p>
            {buttonNumbers.map((number) => {
              return (
                <button
                  key={number}
                  value={number}
                  className={
                    selectedButton == number
                      ? 'button-header-active'
                      : 'button-header'
                  }
                  onClick={(e) => handleSelectNumber(e.target.value)}
                >
                  {number}
                </button>
              );
            })}
          </div>

          <ReelContainer
            reelsContainer={reelsContainer}
            setReelsContainer={setReelsContainer}
          />

          <div className="container-input">
            <button className="minus" onClick={handleClickMinusValue}>
              -
            </button>
            <label className="label-input">
              {t('luckyNumbers.amount.bet')}
            </label>
            <input
              value={betValue}
              max={2000}
              onChange={handleChangeValue}
              required
              type="number"
            />
            <button className="plus" onClick={handleClickPlusValue}>
              +
            </button>
            <button
              ref={buttonBetRef}
              style={{ marginLeft: '10px' }}
              className="button-default"
              onClick={handleOpenModal}
            >
              {t('luckyNumbers.amount.play')}
            </button>
          </div>
        </div>
      </div>

      <ModalSpin
        modalIsOpen={modalIsOpen}
        error={error}
        winNumber={winNumber}
        winValue={winValue}
        winMsg={winMsg}
        winNumberShow={winNumberShow}
        ticket={ticket}
        loading={loading}
        selectedNumbersToSpin={selectedNumbersToSpin}
        closeModal={handleCloseModal}
        selectedNumbers={selectedNumbers}
      />
    </div>
  );
}

export default LuckyNumbers;
