const defaults = {
  'default.ok': 'Ok',
  'default.close': 'Fechar',
};

const session = {
  'session.expired': 'Sessão expirada',
};

const header = {
  'header.balance': 'Saldo',
};

const luckyNumbers = {
  'luckyNumbers.choose': 'Escolha seu número da sorte',
  'luckyNumbers.quickySelect': 'Escolha rápida',
  'luckyNumbers.amount.bet': 'Valor TSH',
  'luckyNumbers.amount.play': 'Jogar',
};

const spin = {
  'spin.ticket': 'BILHETE ',
  'spin.yourPick': 'Suas escolhas',
  'spin.winningNumber': 'Números da vitória',
  'spin.yourWinnings': 'Seus ganhos',
};

const winLose = {
  'winLose.lose': 'tente novamente',
  'winLose.won': 'Você ganhou',
  'winLose.won.firstNumber': 'Você ganhou, primeiro número',
  'winLose.won.firstTwoNumbers': 'Você ganhou, primeiros 2 números',
  'winLose.won.firstThreeNumbers': 'Você ganhou, primeiros 3 números',
  'winLose.won.firstFourNumbers': 'Você ganhou, primeiros 4 números',
  'winLose.won.firstAndLastNumbers': 'Você ganhou, primeiro e último números',
  'winLose.won.lastNumber': 'Você ganhou, último número',
  'winLose.won.middleNumber': 'Você ganhou, número do meio',
};

const modalDefault = {
  'modalDefault.prizes': 'PRIZES',
  'modalDefault.drawPick': 'DRAW/PICK',
  'modalDefault.bet': 'BET',
  'modalDefault.numbersPick': 'PICK',
  'modalDefault.numbersWin': 'WIN',
  'modalDefault.multiplier': 'MULT.',
  'modalDefault.yourWinnings': 'WINNINGS',
};

const pt = {
  ...defaults,
  ...luckyNumbers,
  ...header,
  ...spin,
  ...winLose,
  ...session,
  ...modalDefault,
};

export default pt;
